import { useEffect, useState } from 'react';
import styles from  '../CSS/KillsUpgradePage.module.css'

function KillsUpgreadePage(props) {

    const [amountPriceCount, setAmountPriceCount] = useState(0);
    const [timePriceCount, setTimePriceCount] = useState(0);
    const [sizePriceCount, setSizePriceCount] = useState(0);
    const [multiplicatorPriceCount, setMultiplicatorPriceCount] = useState(0);

    const killAmountPriceMultiplicator = 1.2;
    const timePriceMultiplicator = 1.1;
    const sizePriceMultiplicator = 1.5;
    const killMultiplicatorPriceMultiplicator = 10;

    const killPerKillPrice = 5;
    const timePrice = 5;
    const sizePrice = 10;
    const killMultiplicatorPrice = 1000;
    const unlockMediumPrice = 100000;

    useEffect(()=>{
        setAmountPriceCount(parseFloat(localStorage.getItem(props.difficulty + 'MoneyCountKillPerKill')));
        setTimePriceCount(parseFloat(localStorage.getItem(props.difficulty + 'MoneyCountTime')));
        setSizePriceCount(parseFloat(localStorage.getItem(props.difficulty + 'MoneyCountSize')));
        setMultiplicatorPriceCount(parseFloat(localStorage.getItem(props.difficulty + 'MoneyCountMultiplicator')));
    }, [props.difficulty])

    function updateKillPerKillPrice(){
        if(parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) < killPerKillPrice * (killAmountPriceMultiplicator ** amountPriceCount)){
            return;
        }
        localStorage.setItem(props.difficulty + 'MoneyCountKillPerKill', amountPriceCount + 1);
        setAmountPriceCount(amountPriceCount + 1);
        localStorage.setItem(props.difficulty + 'AimMoney', parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) - killPerKillPrice * (killAmountPriceMultiplicator ** amountPriceCount));
        window.dispatchEvent(new Event('money'));
    }

    function updateTimeToClick(){
        if(parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) < timePrice * (timePriceMultiplicator ** timePriceCount)){
            return;
        }
        localStorage.setItem(props.difficulty + 'MoneyCountTime', timePriceCount + 1);
        setTimePriceCount(timePriceCount + 1);
        localStorage.setItem(props.difficulty + 'Time', 0.1 * parseFloat(1 + localStorage.getItem(props.difficulty + 'MoneyCountTime')));
        localStorage.setItem(props.difficulty + 'AimMoney', parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) - timePrice * (timePriceMultiplicator ** timePriceCount));
        window.dispatchEvent(new Event('money'));
    }

    function updateDotSize(){
        if(parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) < sizePrice * (sizePriceMultiplicator ** sizePriceCount)){
            return;
        }
        localStorage.setItem(props.difficulty + 'MoneyCountSize', sizePriceCount + 1);
        setSizePriceCount(sizePriceCount + 1);
        localStorage.setItem(props.difficulty + 'Radius', 5 + parseFloat(localStorage.getItem(props.difficulty + 'MoneyCountSize')));
        localStorage.setItem(props.difficulty + 'AimMoney', parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) - sizePrice * (sizePriceMultiplicator ** sizePriceCount));
        window.dispatchEvent(new Event('money'));
    }

    function updateKillMultiplicator(){
        if(parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) < killMultiplicatorPrice * (killMultiplicatorPriceMultiplicator ** multiplicatorPriceCount)){
            return;
        }
        localStorage.setItem(props.difficulty + 'MoneyCountMultiplicator', multiplicatorPriceCount + 1);
        setMultiplicatorPriceCount(multiplicatorPriceCount + 1);
        localStorage.setItem(props.difficulty + 'AimMoney', parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) - killMultiplicatorPrice * (killMultiplicatorPriceMultiplicator ** multiplicatorPriceCount));
        window.dispatchEvent(new Event('money'));
    }

    function unlockMedium(){
        if(parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) < unlockMediumPrice){
            return;
        }
        window.alert("Still WIP for now you completed the game");
    }

    return (
      <div className={styles.shop}>
        <button className={styles.shopButton} onClick={updateKillPerKillPrice}>kill amount +1 ({amountPriceCount})<br />{(killPerKillPrice * (killAmountPriceMultiplicator ** amountPriceCount)).toFixed(2)}</button>
        <button className={styles.shopButton} onClick={updateTimeToClick}>time to click +0.1 ({timePriceCount})<br />{(timePrice * (timePriceMultiplicator ** timePriceCount)).toFixed(2)}</button>
        <button className={styles.shopButton} onClick={updateDotSize}>dot size +1 ({sizePriceCount})<br />{(sizePrice * (sizePriceMultiplicator ** sizePriceCount)).toFixed(2)}</button>
        <button className={styles.shopButton} onClick={updateKillMultiplicator}>kill amount x2 ({multiplicatorPriceCount})<br />{(killMultiplicatorPrice * (killMultiplicatorPriceMultiplicator ** multiplicatorPriceCount)).toFixed(2)}</button>
        <button className={styles.shopButton} onClick={unlockMedium}>unlock medium difficulty<br />{(unlockMediumPrice).toFixed(2)}</button>
      </div>
    );
  }
  
  export default KillsUpgreadePage;