import Resources from './Components/Resources';
import KillsUpgreadePage from './Pages/KillsUpgradePage';
import RangePage from './Pages/RangePage'
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Resources />
      <Routes>
        <Route path='/' element={<RangePage />} />
        <Route path='/killUpgrades' element={<KillsUpgreadePage difficulty='easy'/>} />
      </Routes>
    </div>
  );
}

export default App;
