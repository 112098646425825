import React, { useState, useEffect } from 'react';
import style from '../CSS/Resources.module.css'
import {Link} from 'react-router-dom'

const Resources = () => {

    const [aimMoney, setAimMoney] = useState(parseInt(localStorage.getItem('aimMoney')));

    if(localStorage.getItem('easyAimMoney') === null){
        localStorage.setItem('easyAimMoney', 0);
        localStorage.setItem('easyRadius', 5);
        localStorage.setItem('easyTime', 1);
        localStorage.setItem('easyReward', 1);
        localStorage.setItem('easyMoneyCountKillPerKill', 0);
        localStorage.setItem('easyMoneyCountTime', 0);
        localStorage.setItem('easyMoneyCountSize', 0);
        localStorage.setItem('easyMoneyCountMultiplicator', 0);
    }

    useEffect(() => {
        const handleMoney = () => {
            setAimMoney(localStorage.getItem('easyAimMoney'));
        }

        handleMoney();
      
        window.addEventListener('money', handleMoney);
        return () => window.removeEventListener('money', handleMoney);
      }, [])

    return (
        <div>
            <Link to="/"><button className={style.range}>Shooting Range</button></Link>
            <Link to="/killUpgrades"><button className={style.easy}>Easy kills {parseFloat(aimMoney).toFixed(2)}</button></Link>
        </div>
    )
}

export default Resources;