import ShootingRangeField from "../Components/ShootingRangeField";
import styles from "../CSS/RangePage.module.css"

function RangePage() {
    return (
      <div className={styles.layout}>
        <div className={styles.easyRangeFiled}>
          <ShootingRangeField difficulty = 'easy'/>
        </div>
      </div>
    );
  }
  
  export default RangePage;