import React, { useRef, useEffect } from 'react'
import style from '../CSS/ShootingRangeField.module.css'

function ShootingRangeField(props){

    let x = Math.random()*(600 - 2 * parseFloat(localStorage.getItem(props.difficulty + 'Radius'))) + parseFloat(localStorage.getItem(props.difficulty + 'Radius'));
    let y = Math.random()*(300 - 2 * parseFloat(localStorage.getItem(props.difficulty + 'Radius'))) + parseFloat(localStorage.getItem(props.difficulty + 'Radius'));
    let dotId = 0;

    function checkCircleClicked(canvas, event) {
        const rect = canvas.getBoundingClientRect();
        const xClick = event.clientX - rect.left;
        const yClick = event.clientY - rect.top;
        if(Math.abs(x-xClick) <= localStorage.getItem(props.difficulty + 'Radius') && Math.abs(y-yClick) <= localStorage.getItem(props.difficulty + 'Radius')){
            localStorage.setItem(props.difficulty + 'AimMoney', parseFloat(localStorage.getItem(props.difficulty + 'AimMoney')) + (1 + parseFloat(localStorage.getItem(props.difficulty + 'MoneyCountKillPerKill'))) * (2 ** localStorage.getItem(props.difficulty + 'MoneyCountMultiplicator')));
            if(canvasRef.current !== null) redrawDot();
            let dotBevore = dotId;
            setTimeout(function timeout (){
                if(dotBevore === dotId){
                    if(canvasRef.current !== null) redrawDot();
                    dotBevore = dotId;
                    setTimeout(timeout, parseFloat(localStorage.getItem(props.difficulty + 'Time')) * 1000);
                }
            }, parseFloat(localStorage.getItem(props.difficulty + 'Time')) * 1000);
        }
    }

    function redrawDot(){
        x = Math.random()*(600 - 2 * parseFloat(localStorage.getItem(props.difficulty + 'Radius'))) + parseFloat(localStorage.getItem(props.difficulty + 'Radius'));
        y = Math.random()*(300 - 2 * parseFloat(localStorage.getItem(props.difficulty + 'Radius'))) + parseFloat(localStorage.getItem(props.difficulty + 'Radius'));
        window.dispatchEvent(new Event('money'));
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        dotId += 1;
        draw(context);
    }

    const draw = ctx => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.fillStyle = '#000000';
        ctx.beginPath();
        ctx.arc(x, y, localStorage.getItem(props.difficulty + 'Radius'), 0, 2*Math.PI);
        ctx.fill();
      }
    
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.addEventListener('mousedown', function(e) {
            checkCircleClicked(canvas, e);
        })
        draw(context);
    }, [draw]);

    return (
        <canvas width={600} height={300} ref={canvasRef} {...props}/>
    )
}

export default ShootingRangeField;